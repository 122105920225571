/* eslint-disable no-unused-vars */
import styles from "./CopyBtn.module.css";
import { Fragment, useEffect, useRef, useState } from "react";
import { IconVolumnMute, IconVolumn, IconSpinner } from "./Icon";
import { textToSpeech } from "../../store/chat-action";

const TextToSpeech = ({ messageId }) => {
  const [loading, setLoading] = useState(false);
  const [playing, setPlay] = useState(false);
  const [audioSrc, setAudioSrc] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const refAudio = useRef();
  useEffect(() => {
    if (currentTrack && refAudio && refAudio.current) {
      refAudio.current.play();
      setPlay(true);
      refAudio.current.addEventListener("ended", () => {
        if (currentTrackIndex === audioSrc.length - 1) {
          setCurrentTrack(null);
          setCurrentTrackIndex(null);
          setPlay(false);
          return;
        }
        setCurrentTrack(audioSrc[currentTrackIndex + 1]);
        setCurrentTrackIndex(currentTrackIndex + 1);
      });
    }
  }, [currentTrack, refAudio]);

  const stop = () => {
    refAudio.current.pause();
    setPlay(false);
    setCurrentTrack(null);
    setCurrentTrackIndex(null);
  };

  const playAudio = async () => {
    setLoading(true);
    const callAPI = async () => {
      try {
        const response = await textToSpeech(messageId);
        const length = response.data.data;
        const audios = Array.from(Array(length).keys()).map((m) => {
          return `/storage/audio/${messageId}/${m}.mp3`;
        });
        setAudioSrc(audios);
        setCurrentTrack(audios[0]);
        setCurrentTrackIndex(0);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        // Handle error appropriately, e.g., display an error message to the user
      }
    };
    callAPI();
  };

  return (
    <Fragment>
      <audio
        className={`${playing ? "visible" : "invisible"} w-60 md:w-96`}
        // controls
        ref={refAudio}
        src={currentTrack}
        type="audio/mpeg"
      >
        Your browser does not support the audio element.
      </audio>
      <div
        className={`${styles["copy-icon"]} ${styles["text-to-speech"]} flex justify-center items-center gap-1`}
      >
        {loading ? (
          <IconSpinner className="cursor-pointer size-5" />
        ) : (
          <>
            {playing ? (
              <button>
                <IconVolumnMute
                  onClick={stop}
                  className="cursor-pointer size-5"
                />
              </button>
            ) : (
              <button onClick={(e) => playAudio()}>
                <IconVolumn className="cursor-pointer size-5" />
              </button>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default TextToSpeech;
