import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateProfile } from "../../store/user-action";
import { cn } from "../../utils/utils";
import { IconChevronDown } from "../Ui/Icon";

const DEFAULT_LANG = "English";

const Language = ({ className }) => {
  const userDetails = useSelector((state) => state.user.user);
  const languages = useSelector((state) => state.chat.languages);

  const [lang, setNewLang] = useState(userDetails?.lang || DEFAULT_LANG);
  const [open, setOpen] = useState(false);

  const setLang = async (value) => {
    try {
      await updateProfile({ lang: value });
      setNewLang(value);
      setOpen(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const divRef = useRef(null);
  const btnRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current.contains(event.target)) return;
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    document.addEventListener("touchend", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [divRef, btnRef]);

  return (
    <div
      className={cn(
        "absolute right-0 top-[50%] translate-y-[-50%] translate-x-[-50%] bg-[var(--input-bg)]",
        className
      )}
    >
      <div
        ref={btnRef}
        className="inline-flex justify-end w-32 items-center cursor-pointer py-2 pl-3 pr-4"
        onClick={(e) => {
          e.stopPropagation();
          setOpen((pre) => !pre);
        }}
      >
        <span className="capitalize mr-1.5">{lang}</span>
        <IconChevronDown className="w-3 h-3" />
      </div>
      {open && (
        <nav
          ref={divRef}
          className="shadow-md bg-white h-96 absolute border-dark-600/80 border-2 bottom-[100%] right-0 z-[1000] p-1 transition-all duration-500 ease visible overflow-auto"
        >
          <ul>
            {languages.map((m) => {
              return (
                <li
                  className="text-black font-sans hover:text-gray-800 hover:bg-dark-700 no-underline px-5 py-2.5 flex items-center justify-start border-none bg-transparent cursor-pointer w-full text-center text-sm font-semibold m-0 whitespace-nowrap rounded-[6px]"
                  data-discover="true"
                  href=""
                  key={m}
                  onClick={() => setLang(m)}
                >
                  <span className="whitespace-nowrap">{m}</span>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Language;
