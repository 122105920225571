// src/QAPage.js
import React, { useState } from "react";
import { IconChevronDown } from "../../components/Ui/Icon";

const QAPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const generalQA = [
    {
      question: "What is Gospel Truth AI?",
      answer:
        "Gospel Truth AI is an innovative platform designed to assist pastors, elders, church leaders, and teachers in devotional and sermon preparation. It provides powerful tools to streamline research, offering access to resources and features tailored to support you in your ministry work. It creates in minutes devotionals and sermons that can be heard and read in any major language on earth.",
    },
    {
      question: "Who can use Gospael Truth AI?",
      answer:
        "Gospel Truth AI is ideal for pastors, elders, church leaders, and educators involved in religious studies or ministry work. It is user-friendly and accessible for individuals with varying levels of technical expertise.",
    },
    {
      question: "How does Gospel Truth AI work?",
      answer:
        "The platform uses advanced artificial intelligence to search, research, organize, and provide reliable information for sermon and devotional preparation, personal inspiration and devotional and sermon creation. Users can input specific topics or themes, and Gospel Truth AI generates structured, well-researched content to inspire and guide devotional and sermon development.",
    },
  ];
  const membership = [
    {
      question: "Is there a free trial available?",
      answer:
        "Yes! If your employer or church has sponsored your access, you’ll receive a complimentary one-month trial with up to 25 sermons. After the trial period, you can continue with a monthly subscription for $19.95.",
    },
    {
      question: "What happens after the free trial?",
      answer:
        "Once your trial period ends, your account will be deactivated unless you subscribe. The monthly subscription costs $19.95 and provides unlimited sermon creation and ongoing platform access.",
    },
    {
      question: "How do I subscribe after my free trial?",
      answer:
        "You can subscribe by logging into your account and selecting the subscription plan from your profile settings. Payment can be made securely via credit card or other supported payment methods.",
    },
  ];
  const platform = [
    {
      question: "What are the usage limits during the free trial?",
      answer:
        "The free trial allows you to create up to 25 sermons. To create more, you’ll need to upgrade to a monthly subscription.",
    },
    {
      question: "Can I create sermons on specific topics or themes?",
      answer:
        "Yes! You can input specific topics, themes, or scripture references, and Gospel Truth AI will generate relevant content to support your sermon and devotional preparation.",
    },
    {
      question: "Does Gospel Truth AI store my previous sermons?",
      answer:
        "Yes, all sermons you create are saved in your account for future access and editing. You can organize them into folders for easy reference.",
    },
  ];
  const technical = [
    {
      question: "What if I experience technical issues?",
      answer:
        "If you encounter any technical problems, you can contact our support team:",
      email: "📧 Email: Support@Gospeltruthai.com",
      phone: "📞 Phone: 212-415-0199",
    },
    {
      question: "Is my data secure on Gospel Truth AI?",
      answer:
        "Yes, we prioritize your data security. The platform uses industry-standard encryption and secure servers to protect your information.",
    },
    {
      question: "What devices can I use to access Gospel Truth AI?",
      answer:
        "Gospel Truth AI is accessible on desktops, laptops, tablets, and smartphones via your web browser.",
    },
  ];
  const customization = [
    {
      question: "Can I customize my search results?",
      answer:
        "Yes, you can input topics or themes to tailor your search results.",
    },
    {
      question: "Are there additional features for premium subscribers?",
      answer:
        "Subscribers gain access to sermon and devotional creation, advanced search capabilities, and priority customer support.",
    },
    {
      question: "Can my church upgrade to a group subscription plan?",
      answer:
        "Yes! Churches can purchase group plans for their teams. Contact us at <b>sales@Gospeltruthai.com</b> for customized pricing and details.",
    },
  ];
  const account = [
    {
      question: "How do I reset my password?",
      answer:
        "To reset your password, click “Forgot Password” on the login page and follow the instructions sent to your registered email.",
    },
    {
      question: "How do I update my payment information?",
      answer:
        "You can update your payment information in the “Billing” section of your account settings.",
    },
    {
      question: "Can I cancel my subscription?",
      answer:
        "Yes, you can cancel your subscription at any time from your account settings. Your access will continue until the end of your current billing period.",
    },
  ];

  const toggleAnswer = (group, index) => {
    setActiveIndex(
      activeIndex === `${group}${index}` ? null : `${group}${index}`
    );
  };

  return (
    <div className="flex flex-col mx-auto items-center h-full w-full bg-gray-50 bg-no-repeat bg-cover">
      <div className="px-5 py-10 w-full md:!w-3/5">
        <h1 className="text-[#0173B8] text-3xl md:text-5xl">
          Gospel Truth AI FAQ
        </h1>
        <hr className="my-6" />
        <div className="flex flex-col gap-3">
          <h2 className="text-lg md:text-xl">General Questions </h2>
          {generalQA.map((qa, index) => (
            <div key={index} className="p-2">
              <div
                className="flex gap-1 items-center"
                onClick={() => toggleAnswer("general", index)}
              >
                <IconChevronDown
                  className={`${
                    activeIndex === `general${index}` ? "" : "-rotate-90"
                  } w-3 h-3 transition delay-75`}
                />
                <p className="cursor-pointer">{qa.question}</p>
              </div>
              {activeIndex === `general${index}` && (
                <p className="p-2">{qa.answer}</p>
              )}
            </div>
          ))}
          <h2 className="text-lg md:text-xl">Membership and Pricing</h2>
          {membership.map((qa, index) => (
            <div key={index} className="p-2">
              <div
                className="flex gap-1 items-center"
                onClick={() => toggleAnswer("membership", index)}
              >
                <IconChevronDown
                  className={`${
                    activeIndex === `membership${index}` ? "" : "-rotate-90"
                  } w-3 h-3 transition delay-75`}
                />
                <p className="cursor-pointer">{qa.question}</p>
              </div>
              {activeIndex === `membership${index}` && (
                <p className="p-2 transition-opacity duration-300">
                  {qa.answer}
                </p>
              )}
            </div>
          ))}
          <h2 className="text-lg md:text-xl">Platform Features </h2>
          {platform.map((qa, index) => (
            <div key={index} className="p-2">
              <div
                className="flex gap-1 items-center"
                onClick={() => toggleAnswer("platform", index)}
              >
                <IconChevronDown
                  className={`${
                    activeIndex === `platform${index}` ? "" : "-rotate-90"
                  } w-3 h-3 transition delay-75`}
                />
                <p className="cursor-pointer">{qa.question}</p>
              </div>
              {activeIndex === `platform${index}` && (
                <p className="p-2 transition-opacity duration-300">
                  {qa.answer}
                </p>
              )}
            </div>
          ))}
          <h2 className="text-lg md:text-xl">Technical Support</h2>
          {technical.map((qa, index) => (
            <div key={index} className="p-2">
              <div
                className="flex gap-1 items-center"
                onClick={() => toggleAnswer("technical", index)}
              >
                <IconChevronDown
                  className={`${
                    activeIndex === `technical${index}` ? "" : "-rotate-90"
                  } w-3 h-3 transition delay-75`}
                />
                <p className="cursor-pointer">{qa.question}</p>
              </div>
              {activeIndex === `technical${index}` && (
                <>
                  <p className="p-2 transition-opacity duration-300">
                    {qa.answer}
                  </p>
                  <ul>
                    <li>{qa.email}</li>
                    <li>{qa.phone}</li>
                  </ul>
                </>
              )}
            </div>
          ))}
          <h2 className="text-lg md:text-xl">Customization and Upgrades</h2>
          {customization.map((qa, index) => (
            <div key={index} className="p-2">
              <div
                className="flex gap-1 items-center"
                onClick={() => toggleAnswer("customization", index)}
              >
                <IconChevronDown
                  className={`${
                    activeIndex === `customization${index}` ? "" : "-rotate-90"
                  } w-3 h-3 transition delay-75`}
                />
                <p className="cursor-pointer">{qa.question}</p>
              </div>
              {activeIndex === `customization${index}` && (
                <p className="p-2 transition-opacity duration-300">
                  {qa.answer}
                </p>
              )}
            </div>
          ))}
          <h2 className="text-lg md:text-xl">Account Management</h2>
          {account.map((qa, index) => (
            <div key={index} className="p-2">
              <div
                className="flex gap-1 items-center"
                onClick={() => toggleAnswer("account", index)}
              >
                <IconChevronDown
                  className={`${
                    activeIndex === `account${index}` ? "" : "-rotate-90"
                  } w-3 h-3 transition delay-75`}
                />
                <p className="cursor-pointer">{qa.question}</p>
              </div>
              {activeIndex === `account${index}` && (
                <p className="p-2 transition-opacity duration-300">
                  {qa.answer}
                </p>
              )}
            </div>
          ))}
        </div>
        <p className="mt-10">
          For additional questions not covered here, feel free to reach out to
          our support team!
        </p>
      </div>
    </div>
  );
};

export default QAPage;
