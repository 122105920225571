import { useDispatch, useSelector } from "react-redux";
import { IconArrowRight } from "../../Ui/Icon";
// import { chatAction } from "../../../store/chat";
import { sendChatData } from "../../../store/chat-action";
import { CHAT_TYPE } from "../../../constants/index";

const ResponsePromptSection = ({ chatType }) => {
  const relatedQuestions = useSelector((state) => state.chat.relatedQuestions);
  const previousChat = useSelector((state) => state.chat.previousChat);
  const chatHistoryId = useSelector((state) => state.chat.chatHistoryId);

  const dispatch = useDispatch();

  const promptOnClick = (mainText) => {
    // console.log(mainText);

    // dispatch(chatAction.suggestPromptHandler({ prompt: mainText }));
    dispatch(
      sendChatData({
        chatType,
        user: mainText,
        gemini: "",
        isLoader: "yes",
        previousChat,
        chatHistoryId,
      })
    );
  };

  return (
    <ul className="flex flex-col w-full items-center">
      {relatedQuestions.map((p, index) => (
        <li
          onClick={(e) => promptOnClick(p)}
          className="w-full m-1  justify-start cursor-pointer hover:bg-[var(--menuicon-hover-bg)] relative p-2 rounded-md border-b-[1px] border-dashed border-zinc-300 text-left items-center"
          key={p.id + "" + index}
        >
          {chatType === CHAT_TYPE.PUBLIC && index === 0 ? (
            <></>
          ) : (
            <>
              <p>{p}</p>
              <div className="size-10 top-3 font-bold right-1 absolute items-center">
                <IconArrowRight />
              </div>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default ResponsePromptSection;
