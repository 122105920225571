import styles from "./InputSection.module.css";
import { commonIcon } from "../../asset";
import { sendChatData } from "../../store/chat-action";
import { chatAction } from "../../store/chat";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHAT_TYPE } from "../../constants/index";
import Lang from "./Lang";

const InputSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const isLoader = useSelector((state) => state.chat.isLoader);
  const isReplying = useSelector((state) => state.chat.isReplying);
  const chatType = useSelector((state) => state.chat.chatType);

  const previousChat = useSelector((state) => state.chat.previousChat);
  const chatHistoryId = useSelector((state) => state.chat.chatHistoryId);
  const suggestPrompt = useSelector((state) => state.chat.suggestPrompt);
  const [langHidden, setLangHidden] = useState(false);

  const userInputHandler = (e) => {
    setUserInput(e.target.value);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(
      sendChatData({
        chatType,
        user: userInput,
        gemini: "",
        isLoader: "yes",
        previousChat,
        chatHistoryId,
      })
    );
    setUserInput("");
    navigate("/home/app");
  };

  useEffect(() => {
    if (suggestPrompt.length > 0) {
      setUserInput(suggestPrompt);
    }
  }, [suggestPrompt]);

  const handleStop = () => {
    setTimeout(() => {
      dispatch(chatAction.setIsStoped(true));
    }, 1000);
  };

  const cssStype = userInput ? "opacity-70" : "opacity-10";
  const placeholder =
    chatType === CHAT_TYPE.PRIVATE
      ? "Enter a one word topic or theme here..."
      : "Enter a prompt here";

  return (
    <div className={styles["input-main"]}>
      <form onSubmit={onSubmitHandler}>
        <input
          disabled={isLoader}
          onChange={userInputHandler}
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          name="prompt"
          value={userInput}
          onFocus={() => setLangHidden(true)}
          onBlur={() => setLangHidden(false)}
        ></input>
        <Lang
          className={`${
            langHidden ? "hidden md:block md:bg-[var(--input-focus-bg)]" : ""
          }`}
        />
        {isReplying ? (
          <>
            <button
              type="button"
              className="opacity-70 hover:opacity-100"
              onClick={handleStop}
            >
              <img
                className="w-8 h-8"
                src={commonIcon.stopIcon}
                alt="stop icon"
              ></img>
            </button>
          </>
        ) : (
          <button
            disabled={isLoader || isReplying || !userInput}
            type="submit"
            className={`${cssStype} disabled:cursor-default enabled:hover:opacity-100`}
          >
            <img
              className="w-8 h-8 transform rotate-4"
              src={commonIcon.sendIcon}
              alt="send icon"
            ></img>
          </button>
        )}
      </form>
    </div>
  );
};

export default InputSection;
