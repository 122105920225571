/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import styles from "./UserDetails.module.css";
import { themeIcon } from "../../asset";
import { uiAction } from "../../store/ui-gemini";
import { useDispatch, useSelector } from "react-redux";
import { logoutHandler } from "../../store/auth-action";
import { userAction } from "../../store/user";
import { uploads } from "../../store/upload";
import { useNavigate } from "react-router-dom";
import { commonIcon } from "../../asset";
import ImageUpload from "../../components/Upload/UploadImage";
import { IconCamera } from "../Ui/Icon";

const UserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const icon = themeIcon();
  const fileInputRef = useRef(null);

  const userDetsilsClose = () => {
    dispatch(uiAction.toggleUserDetailsShow());
  };

  const onLogoutHandler = () => {
    dispatch(logoutHandler());
    navigate("/home");
  };

  const handleUploadAvatar = async (file) => {
    try {
      setLoading(true);
      console.log(file);
      const formData = new FormData();
      formData.append("uploads", file);

      // Append the JSON data as a string
      formData.append(
        "jsonData",
        JSON.stringify({
          action: "change_avatar",
          type: "image",
        })
      );
      const data = await uploads(formData);
      if (data && data.length > 0) {
        dispatch(userAction.setUserAvatar(data[0].url));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={styles["user-main"]}>
      <div className={styles["user-data"]}>
        <p className={styles["email"]}>{userDetails?.email}</p>
        <div className="flex justify-center">
          <div
            onClick={() => fileInputRef.current.click()}
            className="group relative transition hover:scale-110 overflow-hidden flex items-center justify-center rounded-[50%] shadow-sm ring-1 ring-black ring-opacity-5 cursor-pointer w-[80px] py-2 my-2 h-[80px]"
          >
            <img
              className="w-[70px] h-[70px] rounded-[50%]"
              src={userDetails?.profileImg || commonIcon.defaultAvatar}
              alt="user icon"
            ></img>

            <div
              title="Click to change your avatar"
              className="absolute w-full h-[50%] bottom-0 flex justify-center items-center bg-slate-900 z-0 bg-opacity-50 opacity-0 transition-all duration-300 group-hover:opacity-80 group-hover:[translate:0_-50%]"
            >
              <IconCamera className="text-white w-6 h-6" />
            </div>
          </div>
        </div>
        <ImageUpload
          fileInputRef={fileInputRef}
          onChange={handleUploadAvatar}
        />

        <p className={styles["name"]}>{userDetails?.name?.split(" ")[0]}</p>

        <div className={styles["signout"]} onClick={onLogoutHandler}>
          <img src={icon.signOutIcon} alt="signout"></img>
          <p>Sign out</p>
        </div>
      </div>
      <div className={styles["privacy"]}>
        <a href="/term/privacy-policy">
          <p>Privacy Policy</p>
        </a>
        <p>.</p>
        <a href="/term/terms-of-service">
          <p>Terms of Service</p>
        </a>{" "}
        <p>.</p>
        <a href="/frequently-asked-questions">
          <p>FAQ</p>
        </a>
      </div>

      <div className={styles["cross"]} onClick={userDetsilsClose}>
        <img src={icon.crossIcon} alt="cross"></img>
      </div>
    </div>
  );
};

export default UserDetails;
